import React, { useState, useEffect } from "react";
import { Table, Button, Image } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import "./styles.css";
import { patch } from "../../services/RestService";
import { openNotification } from "../../helpers";
import $ from "jquery";
import XLSX from "xlsx";

const VotingReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(32);

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/voting-report`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.data);
        setTotal(res.data.length);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const approveReport = (id, action) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const formData = new FormData();
    formData.append("acknowledge", true);

    patch(`/voting-report/${id}`, formData, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        openNotification("Acknowledge send to the Observer");
        sendMessage(id);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Observer",
      dataIndex: "user",
      key: "user",
      render: (_, data) => <p>{data.user && data.user && data.user.name}</p>,
    },
    {
      title: "Status",
      dataIndex: "approved",
      key: "approved",
      render: (_, data) => (
        <p>{data.acknowledge ? "Submitted" : "non-submitted"}</p>
      ),
    },
    {
      title: "Circle",
      dataIndex: "circle",
      key: "circle",
    },
    {
      title: "Election Center",
      dataIndex: "electionCenter",
      key: "electionCenter",
    },
    {
      title: "Election Station",
      dataIndex: "electionStation",
      key: "electionStation",
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <div style={{ display: "flex" }}>
          {!data.acknowledge ? (
            <Button
              style={{ marginRight: 10, borderRadius: 15 }}
              onClick={() => {
                approveReport(data._id, "approve");
              }}
              type="primary"
            >
              Acknowledge
            </Button>
          ) : (
            <p
              style={{
                textAlign: "center",
                marginTop: 5,
                color: "green",
                marginRight: 37,
              }}
            >
              Acknowledged
            </p>
          )}
          {
            <Button
              style={{
                marginRight: 10,
                backgroundColor: "#CE1126",
                borderRadius: 15,
              }}
              onClick={() => {
                downloadfile(data);
              }}
              type="primary"
            >
              Download
            </Button>
          }
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  const downloadfile = (oneData) => {
    var createXLSLFormatObj = [];

    /* XLS Head Columns */
    var xlsHeader = [
      "OBSERVER_NAME",
      "OBSERVER_LOCATION",
      "OBSERVER_PHONE",
      "OBSERVER_GENDER",
      "OBSERVER_EMPLOYMENT",
      "OBSERVER_EMAIL",
      "OBSERVER_DEGREE",
      "OBSERVER_DOB",
      "OBSERVER_IMAGE",
      "CIRCLE",
      "ELECTION_CENTER",
      "ELECTION_STATION",
      "CANDIDATE_NAME",
      "CANDIDATE_NO",
      "CANDIDATE_IMAGE",
      "CANDIDATE_CITY",
      "VOTES",
    ];

    /* XLS Rows Data */

    var xlsRows = [];

    if (oneData) {
      oneData.candidates &&
        oneData.candidates.map((d, i) =>
          xlsRows.push({
            OBSERVER_NAME: oneData.user && oneData.user && oneData.user.name,
            OBSERVER_LOCATION:
              oneData.user && oneData.user && oneData.user.location,
            OBSERVER_PHONE: oneData.user && oneData.user && oneData.user.phone,
            OBSERVER_GENDER:
              oneData.user && oneData.user && oneData.user.gender,
            OBSERVER_EMPLOYMENT:
              oneData.user && oneData.user && oneData.user.employment,
            OBSERVER_EMAIL: oneData.user && oneData.user && oneData.user.email,
            OBSERVER_DEGREE:
              oneData.user && oneData.user && oneData.user.degreeTitle,
            OBSERVER_DOB: oneData.user && oneData.user && oneData.user.dob,
            OBSERVER_IMAGE: oneData.user && oneData.user && oneData.user.image,
            CIRCLE: oneData.circle && oneData.circle,
            ELECTION_CENTER: oneData.electionCenter && oneData.electionCenter,
            ELECTION_STATION:
              oneData.electionStation && oneData.electionStation,
            CANDIDATE_NAME:
              oneData.candidates[i] &&
              oneData.candidates[i].candidate &&
              oneData.candidates[i].candidate.fullName,
            CANDIDATE_NO:
              oneData.candidates[i] && oneData.candidates[i].candidateNo,
            CANDIDATE_IMAGE:
              oneData.candidates[i] && oneData.candidates[i].image,
            CANDIDATE_CITY:
              oneData.candidates[i] &&
              oneData.candidates[i].candidate &&
              oneData.candidates[i].candidate.city,
            VOTES: oneData.candidates[i] && oneData.candidates[i].votesNos,
          })
        );
    } else {
      const fullData = data || [];

      fullData.map((data, index) =>
        data.candidates.map((d, dIndex) =>
          xlsRows.push({
            OBSERVER_NAME: data.user && data.user && data.user.name,
            OBSERVER_LOCATION: data.user && data.user && data.user.location,
            OBSERVER_PHONE: data.user && data.user && data.user.phone,
            OBSERVER_GENDER: data.user && data.user && data.user.gender,
            OBSERVER_EMPLOYMENT: data.user && data.user && data.user.employment,
            OBSERVER_EMAIL: data.user && data.user && data.user.email,
            OBSERVER_DEGREE: data.user && data.user && data.user.degreeTitle,
            OBSERVER_DOB: data.user && data.user && data.user.dob,
            OBSERVER_IMAGE: data.user && data.user && data.user.image,
            CIRCLE: data.circle,
            ELECTION_CENTER: data.electionCenter,
            ELECTION_STATION: data.electionStation,
            CANDIDATE_NAME: data.candidates[dIndex].candidate.fullName,
            CANDIDATE_NO: data.candidates[dIndex].candidateNo,
            CANDIDATE_IMAGE:
              data.candidates[dIndex] && data.candidates[dIndex].image,
            CANDIDATE_CITY:
              data.candidates[dIndex] &&
              data.candidates[dIndex].candidate &&
              data.candidates[dIndex].candidate.city,
            VOTES: data.candidates[dIndex] && data.candidates[dIndex].votesNos,
          })
        )
      );
    }

    createXLSLFormatObj.push(xlsHeader);

    $.each(xlsRows, function (index, value) {
      var innerRowData = [];
      $.each(value, function (ind, val) {
        innerRowData.push(val);
      });
      createXLSLFormatObj.push(innerRowData);
    });

    /* File Name */
    var filename = "Report.xlsx";

    /* Sheet Name */
    var ws_name = "Report";

    if (typeof console !== "undefined") console.log(new Date());
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* Write workbook and Download */
    if (typeof console !== "undefined") console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== "undefined") console.log(new Date());
  };

  const expandedColumns = [
    {
      title: "Candidate Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (_, data) => <p>{data.candidate.fullName}</p>,
    },
    {
      title: "Candidate No",
      dataIndex: "candidateNo",
      key: "candidateNo",
    },
    {
      title: "Votes ",
      dataIndex: "votesNos",
      key: "votesNos",
    },
    {
      title: "Image ",
      dataIndex: "image",
      key: "image",
      render: (_, data) => (
        <div style={{ display: "flex" }}>
          <Image
            style={{ width: 50, height: 50, objectFit: "cover" }}
            src={data.image && data.image}
          />
        </div>
      ),
    },
  ];

  const sendMessage = (user) => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      remarks: [
        {
          text: "Voting Report Recieved By Admin",
        },
      ],
    };

    patch(`/auth/update/${user}`, data, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        openNotification("Acknowledgement Sent Successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={11} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Voting Report</h1>
            <div>
              <Button
                style={{
                  marginRight: 10,
                  backgroundColor: "#CE1126",
                  borderRadius: 15,
                }}
                onClick={() => {
                  downloadfile();
                }}
                type="primary"
              >
                Download Report
              </Button>
              {/* <Upload {...uploadProps}><Button style={{marginRight: 10, backgroundColor: '#CE1126', borderRadius:15}} type='primary'>Upload Candidates</Button></Upload> */}
            </div>
          </div>
          <div>
            <div
              className="table-card"
              style={{ marginTop: 20, width: "70vw" }}
            >
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: true,
                  responsive: true,

                  showTotal: (total, range) => `${total} records`,
                }}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table
                      loading={loading}
                      scroll={{ x: "500px" }}
                      pagination={false}
                      dataSource={record.candidates}
                      columns={expandedColumns}
                    />
                  ),
                }}
                rowKey="_id"
                onChange={handleTableChange}
                scroll={{ x: true }}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VotingReport;
