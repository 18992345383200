import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu, Modal, Button, Upload } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import "./styles.css";
import { MoreOutlined } from "@ant-design/icons";
import { deleteService } from "../../services/RestService";
import { post } from "../../services/RestService";
import EditModal from "./EditModal";
import { openNotification } from "../../helpers";

const Candidates = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState({});
  const [action, setAction] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [total, setTotal] = useState(32);

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/candidate?page=${
        page ? page : 1
      }&perPage=10`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.data.docs);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/candidate/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Election Circle",
      dataIndex: "circle",
      key: "circle",
    },
    {
      title: "Candidate Number",
      dataIndex: "candidateNumber",
      key: "candidateNumber",
    },
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setEditModal(true);
            setAction("view");
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setEditModal(true);
            setAction("edit");
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => deletePost(data._id)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const onCSVFileChange = async (event) => {
    const data = event.file.originFileObj;
    const formData = new FormData();
    formData.append("data", data);
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    post("/candidate/upload", formData, options)
      .then((res) => {
        setLoading(false);
        openNotification("Data Uploaded Successfully");
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const uploadProps = {
    accept: ".xlsx, .xls",
    showUploadList: false,
    customRequest: () => {},
    onChange: onCSVFileChange,
    multiple: false,
    maxCount: 1,
    onRemove: () => {
      setSelectedFile(null);
    },
  };

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={5} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Candidates</h1>
            <div>
              <Button
                style={{
                  marginRight: 10,
                  backgroundColor: "black",
                  borderRadius: 15,
                }}
                onClick={() => {
                  setViewData(data);
                  setEditModal(true);
                  setAction("add");
                }}
                type="primary"
              >
                Add New Candidates
              </Button>
              <Upload {...uploadProps}>
                <Button
                  style={{
                    marginRight: 10,
                    backgroundColor: "#CE1126",
                    borderRadius: 15,
                  }}
                  type="primary"
                >
                  Upload Candidates
                </Button>
              </Upload>
            </div>
          </div>
          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: false,
                  showSizeChanger: false,

                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        destroyOnClose
        closable
        onCancel={() => {
          setEditModal(false);
          setViewData({});
        }}
        visible={editModal}
      >
        <EditModal
          action={action}
          data={viewData}
          onCancel={() => setEditModal(false)}
          fetchData={getPostTable}
        />
      </Modal>
    </>
  );
};

export default Candidates;
