import axios from "axios";

const BASE_URL = "https://api.baghdad12.com/api";

function handleError(error, reject) {
  let err = "";

  if (error && error.response && error.response.data)
    err = error.response.data.msg || error.response.data;
  else err = "please check internet connection";

  err = err || "plaease try again later";

  reject(err);
}

const get = (url, options = {}, auth) => {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`${BASE_URL}${url}`, options)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        handleError(error, reject);
      });
  });
};

const post = (url, payload, options = {}) => {
  return new Promise(async (resolve, reject) => {
    console.log(payload, options);

    axios
      .post(`${BASE_URL}${url}`, payload, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("ERROR ::: ", error);
        console.log("ERROR RESPONSE ::: ", error.response);
        handleError(error, reject);
      });
  });
};

const patch = (url, payload, options = {}) => {
  return new Promise(async (resolve, reject) => {
    console.log(payload, options);

    axios
      .patch(`${BASE_URL}${url}`, payload, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("ERROR ::: ", error);
        console.log("ERROR RESPONSE ::: ", error.response);
        handleError(error, reject);
      });
  });
};

const put = (url, payload, options = {}) => {
  return new Promise(async (resolve, reject) => {
    console.log(payload, options);

    axios
      .put(`${BASE_URL}${url}`, payload, options)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.log("ERROR>>>", error.response);
        handleError(error, reject);
      });
  });
};

const deleteService = (url, options = {}) => {
  return new Promise(async (resolve, reject) => {
    axios
      .delete(`${BASE_URL}${url}`, options)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.log("ERROR>>>", error.response);
        handleError(error, reject);
      });
  });
};

export { get, post, put, patch, deleteService };
