import React from "react";
import "./styles.css";

const FirstCard = ({ heading, count, icon, styles, onClick }) => {
  return (
    <div onClick={onClick} className="first-card" styles={{ ...styles }}>
      <h3>{heading}</h3>
      <h1 style={{ textAlign: "center" }}>
        <span style={{ color: "green" }}>{icon && "↑"} </span>
        {count}
      </h1>
    </div>
  );
};

export default FirstCard;
