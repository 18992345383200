import React, { useState, useEffect, useRef } from "react";
import { Form, notification, Upload } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import { FullPageLoader } from "../../components/Loaders";
import { get, patch } from "../../services/RestService";
import JoditEditor from "jodit-react";

const ElectionMessage = () => {
  const [title, setTitle] = useState("");
  const [description, setDiscription] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const reset = () => {
    setTitle("");
    setDiscription("");
    setImage(null);
    setSelectedFile([]);
  };

  const getData = () => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get("/elcMessage/614e30b7cfbb46d7d0b48861", options)
      .then((res) => {
        setLoading(false);
        setTitle(res.title);
        setDiscription(res.message);
        const imgList = [];
        imgList.push({
          uid: 1,
          name: `image${2}.png`,
          status: "done",
          url: res.image,
        });
        setSelectedFile(imgList);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("title", title);
    data.append("message", description);
    if (selectedFile) {
      selectedFile.forEach((file) => {
        data.append("image", file.originFileObj);
      });
    }

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/elcMessage/614e30b7cfbb46d7d0b48861`, data, options)
      .then((res) => {
        setLoading(false);
        getData();
        openNotification("Data Updated Successfully");
        reset();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fileSelected = ({ file, fileList: newFileList }) => {
    setSelectedFile(newFileList);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={22} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Manage Election Message</h1>
          </div>

          {loading ? (
            <FullPageLoader />
          ) : (
            <Form onFinish={onSubmit}>
              <div style={{ display: "flex" }}>
                <InputBox
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  label="Title"
                  type="text"
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <p
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    fontWeight: "bold",
                  }}
                >
                  Upload Images
                </p>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture-card"
                  fileList={selectedFile}
                  onChange={fileSelected}
                >
                  {selectedFile.length >= 1 ? null : "+ Upload"}
                </Upload>
              </div>

              <div>
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                >
                  Content
                </p>
                <JoditEditor
                  ref={editor}
                  value={description}
                  config={config}
                  tabIndex={5} // tabIndex of textarea
                  onBlur={(newContent) => setDiscription(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </div>

              <div className="flex-center">
                <div className="btns-wrap">
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="create-btn" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default ElectionMessage;
