import React from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
export default function SideBarItem({ icon, title, active, nav }) {
  const history = useHistory();
  return (
    <div
      style={{ textDecoration: "none" }}
      onClick={() => history.push(nav)}
      className={`sidebar-item ${active === true ? "sidebaractive" : ""}`}
    >
      <img alt="icon" src={icon} />
      <p style={{ margin: 0, fontSize: 14, marginLeft: 10 }}>{title}</p>
    </div>
  );
}
