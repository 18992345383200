import { SET_CURRENT_USER, LOGIN_ERROR } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  loading: false,
  error: "",
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
