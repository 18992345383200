import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import { patch, post } from "../../services/RestService";

const EditModal = ({ data, onCancel, fetchData, action }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [cNumber, setCNumber] = useState();
  const [circle, setCircle] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    if (action === "view" || action === "edit") {
      setName(data.fullName);
      setCNumber(data.candidateNumber);
      setCircle(data.circle);
      setCity(data.city);
    }
  }, [data, action]);

  const onSubmit = (values) => {
    setLoading(true);

    const edata = {
      circle: circle,
      fullName: name,
      sex: "male",
      city: city,
      candidateNumber: cNumber,
    };

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("myid", data._id);

    patch(`/candidate/${data._id}`, edata, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Edited Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const onAddSubmit = (values) => {
    setLoading(true);
    const data = {
      circle: circle,
      fullName: name,
      sex: "male",
      city: city,
      candidateNumber: cNumber,
    };
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    post(`/candidate`, data, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Added Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  return (
    <div style={{ padding: 10 }}>
      <h1 style={{ textAlign: "center" }}>
        {action === "edit"
          ? "Edit Candidate"
          : action === "view"
          ? "View Candidate"
          : "Add Candidate"}
      </h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="FullName"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={city}
          onChange={(e) => setCity(e.target.value)}
          label="City"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={cNumber}
          onChange={(e) => setCNumber(e.target.value)}
          label="Candidate Number"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={circle}
          onChange={(e) => setCircle(e.target.value)}
          label="Circle"
          type="text"
        />
      </div>

      {(action === "add" || action === "edit") && (
        <div className="flex-center">
          <div className="btns-wrap">
            <button
              type="button"
              onClick={() => onCancel()}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => (action === "edit" ? onSubmit() : onAddSubmit())}
              className="create-btn"
              type="submit"
            >
              Upload
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditModal;
