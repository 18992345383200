import { Image } from "antd";
import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import UploadImage from "../../components/UploadImage";
import { patch, post } from "../../services/RestService";
import TextArea from "../../components/TextArea";

const EditModal = ({ data, onCancel, fetchData, action }) => {
  const [title, setTitle] = useState();
  const [location, setLocation] = useState(data.location);
  const [image, setImage] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  console.log(data);

  useEffect(() => {
    if (action === "view" || action === "edit") {
      setTitle(data.name);
      setLocation(data.location);
      setPhone(data.phone);
      setEmail(data.email);
      setDescription(data.description);
      setImageUrl(data.images.length > 0 && data.images[0] && data.images[0]);
    }
  }, [data, action]);

  const onSubmit = (values) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("name", title);
    dataForm.append("location", location);
    dataForm.append("phone", phone);
    dataForm.append("email", email);
    dataForm.append("description", description);
    image && dataForm.append("images", image);

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/election-center/${data._id}`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Edited Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const onAddSubmit = (values) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("name", title);
    dataForm.append("location", location);
    dataForm.append("phone", phone);
    dataForm.append("email", email);
    dataForm.append("description", description);
    image && dataForm.append("images", image);

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    post(`/election-center`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Edited Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };
  return (
    <div style={{ padding: 10 }}>
      <h1 style={{ textAlign: "center" }}>
        {action === "edit"
          ? "Edit Office"
          : action === "view"
          ? "View Office"
          : "Add Office"}
      </h1>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label="Name"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          label="Location"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          label="Phone"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
        />
      </div>

      <TextArea
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <div style={{ display: "flex", marginTop: 10 }}>
        <Image
          style={{
            height: 200,
            width: 200,
            objectFit: "contain",
            border: "1px solid grey",
            alignSelf: "center",
          }}
          src={image ? URL.createObjectURL(image) : imageUrl}
        />
      </div>

      {(action === "add" || action === "edit") && (
        <div style={{ width: 100 }}>
          <UploadImage setImage={setImage} image={image} label="Upload" />
        </div>
      )}

      {/* {stations.map((x ,i) => (
                  <>
                  <div style={{ display: 'flex', justifyContent:'space-between'}}>
                    <InputBox value={x.station} onChange={(e)=> handleInputChange(e, i)} label="Stations" type="text" />
                  </div>
                  {(action === 'add' || action === 'edit') && <Button style={{ marginTop: 10}} onClick={()=> handleRemoveClick(i)} size='small'>Remove</Button>}
                  </>
                ))}     */}

      {/* {(action === 'add' || action === 'edit') &&<div> 
                <Button onClick={()=> handleAddClick()} style={{ marginTop: 30}}>Add more Stations</Button>  
                </div> } */}

      {(action === "add" || action === "edit") && (
        <div className="flex-center">
          <div className="btns-wrap">
            <button
              type="button"
              onClick={() => onCancel()}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => (action === "edit" ? onSubmit() : onAddSubmit())}
              className="create-btn"
            >
              Upload
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditModal;
