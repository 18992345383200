import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Dropdown, Badge, Divider } from "antd";
import { BellFilled } from "@ant-design/icons";
import "./styles.css";
import { logout } from "../../helpers";
import { io } from "socket.io-client";
import { get, patch } from "../../services/RestService";

const NavBar = ({ getReportData, getPostTable }) => {
  const history = useHistory();
  const [notificationList, setNotification] = useState([]);

  const getNotification = () => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get("/notifications", options)
      .then((res) => {
        console.log(res);
        setNotification(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getViewed = (id) => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    patch(`/notifications/isViewed/${id}`, null, options)
      .then((res) => {
        console.log(res);
        getNotification();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getNotification();
  }, []);

  const socket = io("https://api.baghdad12.com/", {
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log(socket.id); // x8WIv7-mJelg7on_ALbx
  });

  socket.on("new_user", (data) => {
    getNotification();
    getPostTable && getPostTable();
  });

  socket.on("new_report", (data) => {
    getNotification();
    getReportData && getReportData();
  });

  const notifications = (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          borderRadius: 10,
          maxWidth: 300,
          border: "1px solid black",
        }}
      >
        <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
          Notifications
        </h4>
        <Divider />
        <div className="main-notifs">
          {notificationList &&
            notificationList.map((data, i) => (
              <p
                onClick={() => {
                  history.push(
                    data.type === "signup"
                      ? `viewobserver?id=${data.user_id}`
                      : "votingreport"
                  );
                  getViewed(data._id);
                }}
                key={i}
                className="notif-p"
              >
                {data.message}
              </p>
            ))}
        </div>
      </div>
    </>
  );

  return (
    <nav className="nav-bar">
      <div style={{ marginRight: 30 }}>
        <Dropdown
          overlay={notifications}
          trigger={["click"]}
          placement="bottomCenter"
        >
          <Badge
            size="default"
            count={notificationList && notificationList.length}
            offset={[-5, 10]}
          >
            <BellFilled style={{ color: "white", fontSize: 40 }} />
          </Badge>
        </Dropdown>
      </div>
      {/* <Dropdown overlay={menu} trigger={['click']} placement='bottomCenter' >
        <Avatar size={54} />
      </Dropdown> */}
      <h1
        onClick={() => logout(history)}
        style={{ color: "white", cursor: "pointer" }}
      >
        Logout
      </h1>
    </nav>
  );
};
export default NavBar;
