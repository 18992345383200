import React from "react";
export default function InputBox({
  width,
  name,
  type,
  classes,
  value,
  onChange,
  label,
  placeholder,
  hasButton,
}) {
  return (
    <>
      <div style={{ width: width }} className="cst-textinput cst-text-width">
        <label className="fw600 fs24 lh48">{label}</label>
        <input
          value={value}
          onChange={onChange}
          name={name}
          className={classes}
          type={type}
        />
      </div>
    </>
  );
}
