import React from "react";
import "./styles.css";
import { Select } from "antd";
const { Option } = Select;

export default function SelectBox({
  width,
  data,
  name,
  type,
  classes,
  label,
  placeholder,
  hasButton,
  onChange,
  defaultValue,
  value,
}) {
  return (
    <>
      <div className="cst-textinput cst-text-width">
        <label className="fw600 fs24 lh48">{label}</label>
        <Select
          value={value}
          style={{ borderRadius: 25, width: width }}
          defaultValue={defaultValue ? defaultValue : "Select"}
          onChange={onChange}
        >
          {data.map((d, i) => (
            <Option key={i} value={d.value}>
              {d.title}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
}
