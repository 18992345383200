import { useLocation } from "react-router"
import { notification } from "antd"
import { SmileOutlined } from "@ant-design/icons"

export const logout = (history) => {
  localStorage.clear()
  history && history.push('/')
  window.location.reload()
}

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search)
}

export const openNotification = (text) => {
  notification.open({
    message: text,
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
  });
};

export const CHART_TYPES = [{ value: 'bar', label: 'Bar' }, { value: 'line', label: 'Line' }, { value: 'doughnut', label: 'Doughnut' }, { value: 'pie', label: 'Pie' }, { value: 'polar', label: 'Polar' }]
export const CHART_OPTIONS = { scales: { yAxes: [{ ticks: { beginAtZero: true, precision: 0 } }] } }
export const CHART_DATASET_OPTIONS = {
  backgroundColor: [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 99, 232, 0.2)',
    'rgba(54, 162, 135, 0.2)',
    'rgba(255, 206, 186, 0.2)',
    'rgba(75, 192, 12, 0.2)',
    'rgba(153, 202, 55, 0.2)',
    'rgba(25, 159, 64, 0.2)'
  ],
  borderColor: [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 32, 1)',
    'rgba(54, 162, 35, 1)',
    'rgba(255, 206, 186, 1)',
    'rgba(75, 292, 192, 1)',
    'rgba(153, 202, 255, 1)',
    'rgba(255, 259, 64, 1)'
  ],
  borderWidth: 1,
  barThickness: 30
}