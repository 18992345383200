import { Form } from "antd";
import React, { useEffect, useState } from "react";
import Bg from "../../assets/img/bg-login.png";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authAction";
import "./styles.css";
import { useHistory } from "react-router";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const onFinish = (values) => {
    dispatch(loginUser(values));
  };

  const [visible, setVisible] = useState();

  useEffect(() => {
    auth.isAuthenticated && history.push("/home");
  }, [auth, history]);

  return (
    <>
      <div className="login-wrapper">
        <Form className="login-form" onFinish={onFinish}>
          <p>{auth.error}</p>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <div className="input-box">
              <input
                className="non-outline"
                type="email"
                placeholder="Enter Email"
                autoComplete="off"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <div
              className="input-box"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                className="non-outline"
                type={visible ? "text" : "password"}
                placeholder="******"
                autoComplete="off"
              />
              {visible ? (
                <EyeInvisibleOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => setVisible(!visible)}
                />
              ) : (
                <EyeOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => setVisible(!visible)}
                />
              )}
            </div>
          </Form.Item>
          <div>
            <button type="submit" className="login-btn">
              Login
            </button>
          </div>
        </Form>
        <img alt="sd" className="bg-pic-login" src={Bg} />
      </div>
    </>
  );
};

export default Login;
