import { LOGIN_ERROR, SET_CURRENT_USER } from "./types";
import { post } from "../../services/RestService";

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  post("/auth/login", userData)
    .then((res) => {
      const { token, user } = res;
      // Set token to ls
      localStorage.setItem("token", token);
      //set Data
      dispatch(setCurrentUser(user));
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: LOGIN_ERROR,
        payload: err.message,
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("token");
  // Remove auth header for future requests
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
