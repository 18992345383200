import React from "react";
import SideBarItem from "./sidebaritem";
import "react-pro-sidebar/dist/css/styles.css";
import "./styles.css";
import Logo1 from "../../assets/img/iraqilogo.png";
import DshboardIcon from "../../assets/img/dashboard.png";
import img1 from "../../assets/img/1.png";
import img2 from "../../assets/img/2.png";
import img3 from "../../assets/img/3.png";
import img4 from "../../assets/img/4.png";
import img5 from "../../assets/img/5.png";
import img7 from "../../assets/img/7.png";
import img8 from "../../assets/img/8.png";

export default function Sidebar(props) {
  return (
    <div id="cst-sidebar" className="cst-sidebar">
      <div className="pos-abs">
        {/* <MenuOutlined style={{ fontSize: 20, color: "white", float:'right', padding:20}} /> */}
        <div className="side-bar-logo">
          <img
            alt="logo"
            src={Logo1}
            style={{ objectFit: "contain", height: 100, width: 250 }}
          />
        </div>
        <SideBarItem
          nav="/home"
          icon={DshboardIcon}
          active={props.active === 0 ? true : false}
          title="Dashboard"
        />
        <SideBarItem
          nav="/uploadcontent"
          icon={img1}
          active={props.active === 1 ? true : false}
          title="Upload Content"
        />
        <SideBarItem
          nav="/managecontent"
          icon={img2}
          active={props.active === 2 ? true : false}
          title="Manage Content"
        />
        <SideBarItem
          nav="/electionmessage"
          icon={img2}
          active={props.active === 22 ? true : false}
          title="Election Message"
        />
        <SideBarItem
          nav="/observer"
          icon={img3}
          active={props.active === 3 ? true : false}
          title="Observers"
        />
        <SideBarItem
          nav="/surveys"
          icon={img4}
          active={props.active === 4 ? true : false}
          title="Surveys"
        />
        <SideBarItem
          nav="/managepages"
          icon={img2}
          active={props.active === 10 ? true : false}
          title="Manage Pages"
        />
        <SideBarItem
          nav="/candidates"
          icon={img5}
          active={props.active === 5 ? true : false}
          title="Candidates"
        />
        <SideBarItem
          nav="/members"
          icon={img7}
          active={props.active === 6 ? true : false}
          title="Members"
        />
        <SideBarItem
          nav="/offices"
          icon={img8}
          active={props.active === 8 ? true : false}
          title="Offices"
        />
        <SideBarItem
          nav="/media"
          icon={img7}
          active={props.active === 9 ? true : false}
          title="Media"
        />
        <SideBarItem
          nav="/votingreport"
          icon={img2}
          active={props.active === 11 ? true : false}
          title="Voting Report"
        />
        <SideBarItem
          nav="/addreport"
          icon={img2}
          active={props.active === 12 ? true : false}
          title="Add Report"
        />
        <SideBarItem
          nav="/addstatistics"
          icon={img2}
          active={props.active === 13 ? true : false}
          title="Add Statistics"
        />
      </div>
    </div>
  );
}
