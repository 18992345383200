import { Image, Divider } from 'antd'
import React from 'react'

const ViewModal = ({data}) => {
  return (
    <div style={{ padding: 10}}>
      <h1 style={{ textAlign: 'center' }}>View Post</h1>
      <h2 style={{ textAlign: 'center' }}>{data.postType}</h2>
      <p style={{ margintop: 10}}>Date: {data.publishDate && data.publishDate.substring(0,10)}</p>
      <Image style={{ width:'100%', height:300, objectFit:'cover', alignSelf:'center'}} src={data.image && data.image}/>
      <Divider/>
      
      <div>
        <h2>Title</h2>
        <p>{data.title}</p>
      </div>
      <Divider />
      <div>
        <h2>Description</h2>
        <div dangerouslySetInnerHTML={{ __html: data.description }} />;
      </div>
      <Divider />
      {data.postType === 'Event' && <div>
        <h2>Event Location</h2>
        <p>{data.eventLocation}</p>
      </div>}
    </div>
  )
}

export default ViewModal
