import React, { useState, useRef } from "react";
import { Form, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import SelectBox from "../../components/Select";
import UploadImage from "../../components/UploadImage";
import { FullPageLoader } from "../../components/Loaders";
import { post } from "../../services/RestService";
import JoditEditor from "jodit-react";

const UploadContent = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDiscription] = useState("");
  const [date, setDate] = useState("");
  const [eventLocation, setEventLocation] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const reset = () => {
    setTitle("");
    setCategory("");
    setDiscription("");
    setImage(null);
    setDate("");
  };

  const onSubmit = (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("title", title);
    data.append("postType", category);
    data.append("publishDate", date);
    data.append("description", description);
    category === "Event" && data.append("eventLocation", eventLocation);
    data.append("image", image);

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    post("/post", data, options)
      .then((res) => {
        setLoading(false);
        openNotification("Data Uploaded Successfully");
        reset();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const selectData = [
    { value: "Blog", title: "Blogs" },
    { value: "Event", title: "Events" },
    { value: "News", title: "News" },
    { value: "ElectionMessage", title: "Election Message" },
  ];

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={1} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Upload Content</h1>
          </div>

          {loading ? (
            <FullPageLoader />
          ) : (
            <Form onFinish={onSubmit}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputBox
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  label="Title"
                  type="text"
                />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item>
                    <div style={{ marginLeft: 20 }}>
                      <SelectBox
                        width={180}
                        data={selectData}
                        label="Category"
                        onChange={(e) => setCategory(e)}
                      />
                    </div>
                  </Form.Item>
                  <div style={{ width: 100, marginLeft: 20, marginRight: 20 }}>
                    <UploadImage
                      setImage={setImage}
                      image={image}
                      label="Upload"
                    />
                  </div>
                  <Form.Item>
                    <InputBox
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      label="Date"
                      type="date"
                    />
                  </Form.Item>
                </div>
              </div>

              {category === "Event" && (
                <InputBox
                  value={eventLocation}
                  onChange={(e) => setEventLocation(e.target.value)}
                  label="Event Location"
                  type="text"
                />
              )}

              <div>
                <p style={{ fontWeight: "bold", marginBottom: 10 }}>
                  Description
                </p>
                <JoditEditor
                  ref={editor}
                  value={description}
                  config={config}
                  tabIndex={5} // tabIndex of textarea
                  onBlur={(newContent) => setDiscription(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </div>

              <div className="flex-center">
                <div className="btns-wrap">
                  <button onClick={() => reset()} className="cancel-btn">
                    Cancel
                  </button>
                  <button className="create-btn" type="submit">
                    Upload
                  </button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadContent;
