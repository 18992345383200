import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/login";
import ProtectedRoute from "./services/ProtectedRoutes";
import { Provider } from "react-redux";
import store from "./redux/store";
import UploadContent from "./pages/UploadContent";
import ManageContent from "./pages/ManageContent";
import Observer from "./pages/Observers";
import ViewObserver from "./pages/Observers/ViewObserver";
import Survey from "./pages/Survey";
import AddEditSurvey from "./pages/Survey/AddSurvey";
import Offices from "./pages/Offices";
import ManagePages from "./pages/ManagePages";
import Candidates from "./pages/Candidates";
import Members from "./pages/Members";
import Media from "./pages/Media";
import VotingReport from "./pages/VotingReport";
import AddStatistics from "./pages/AddStatistics";
import AddReport from "./pages/AddReport";
import ElectionMessage from "./pages/ElectionMessage";

function App() {
  const token = localStorage.getItem("token");

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            {token ? <Redirect to="/home" /> : <Login />}
          </Route>
          <ProtectedRoute exact path="/home" component={Home} />
          <ProtectedRoute
            exact
            path="/uploadcontent"
            component={UploadContent}
          />
          <ProtectedRoute
            exact
            path="/managecontent"
            component={ManageContent}
          />
          <ProtectedRoute exact path="/observer" component={Observer} />
          <ProtectedRoute exact path="/viewobserver" component={ViewObserver} />
          <ProtectedRoute exact path="/surveys" component={Survey} />
          <ProtectedRoute
            exact
            path="/addeditsurvey"
            component={AddEditSurvey}
          />
          <ProtectedRoute exact path="/offices" component={Offices} />
          <ProtectedRoute exact path="/managepages" component={ManagePages} />
          <ProtectedRoute
            exact
            path="/electionmessage"
            component={ElectionMessage}
          />
          <ProtectedRoute exact path="/candidates" component={Candidates} />
          <ProtectedRoute exact path="/members" component={Members} />
          <ProtectedRoute exact path="/media" component={Media} />
          <ProtectedRoute exact path="/votingreport" component={VotingReport} />
          <ProtectedRoute exact path="/addreport" component={AddReport} />
          <ProtectedRoute
            exact
            path="/addstatistics"
            component={AddStatistics}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
