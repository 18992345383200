import { Image } from "antd";
import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/Select";
import UploadImage from "../../components/UploadImage";
import { patch, post, get } from "../../services/RestService";

const EditModal = ({ data, onCancel, fetchData, action }) => {
  const [title, setTitle] = useState();
  const [email, setEmail] = useState(data.location);
  const [sex, setSex] = useState(data.location);
  const [location, setLocation] = useState();
  const [eCenter, setECenter] = useState();
  const [role, setRole] = useState();
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [electionCenter, setElectionCenter] = useState([]);
  const token = localStorage.getItem("token");
  var options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (action === "view" || action === "edit") {
      setTitle(data.name);
      setEmail(data.email);
      setLocation(data.location);
      setECenter(data.electionCenter && data.electionCenter._id);
      setSex(data.gender);
      setRole(data.role);
      setImageUrl(data.image && data.image);
    }

    get("/election-center", options).then((res) => {
      const electionData = res.docs;
      const dt = [];
      electionData &&
        electionData.map((data) =>
          dt.push({ title: data.name, value: data._id })
        );
      setElectionCenter(dt);
    });
  }, [data]);

  const onEditSubmit = (values) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("name", title);
    dataForm.append("location", location);
    //dataForm.append('email', email)
    dataForm.append("gender", sex);
    dataForm.append("role", role);
    dataForm.append("approved", true);
    dataForm.append("electionCenter", "623da487a1f8f337d8010fa4");
    dataForm.append("userType", "member");
    dataForm.append("image", image);

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/auth/update/${data._id}`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Member Edited Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const onAddSubmit = (values) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("name", title);
    dataForm.append("location", location);
    dataForm.append("email", email);
    dataForm.append("gender", sex);
    dataForm.append("role", role);
    dataForm.append("approved", true);
    dataForm.append("electionCenter", "623da487a1f8f337d8010fa4");
    dataForm.append("userType", "member");
    dataForm.append("image", image);
    dataForm.append("password", "member");

    var options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    post(`/auth/signup`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Member Added Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const sData = [
    { title: "Male", value: "Male" },
    { title: "FeMale", value: "FeMale" },
  ];

  return (
    <div style={{ padding: 10 }}>
      <h1 style={{ textAlign: "center" }}>
        {action === "edit"
          ? "Edit Member"
          : action === "view"
          ? "View Member"
          : "Add Member"}
      </h1>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label="Name"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputBox
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          label="Location"
          type="text"
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginRight: 10 }}>
          <SelectBox
            width={200}
            data={sData}
            value={sex}
            label="Sex"
            onChange={(e) => setSex(e)}
          />
        </div>
        <InputBox
          value={role}
          onChange={(e) => setRole(e.target.value)}
          label="Designation"
          type="text"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Image
          style={{
            border: "1px solid grey",
            width: 100,
            height: 100,
            objectFit: "cover",
            alignSelf: "center",
          }}
          src={image ? URL.createObjectURL(image) : imageUrl}
        />
      </div>

      {(action === "add" || action === "edit") && (
        <div style={{ width: 100 }}>
          <UploadImage setImage={setImage} image={image} label="Upload" />
        </div>
      )}

      {(action === "add" || action === "edit") && (
        <div className="flex-center">
          <div className="btns-wrap">
            <button
              type="button"
              onClick={() => onCancel()}
              className="cancel-btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() =>
                action === "edit" ? onEditSubmit() : onAddSubmit()
              }
              className="create-btn"
              type="submit"
            >
              Upload
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditModal;
