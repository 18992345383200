import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu, Modal } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import FirstCard from "../../components/FirstCard";
import "./styles.css";
import { MoreOutlined } from "@ant-design/icons";
import ViewModal from "./ViewModal";
import { deleteService, get } from "../../services/RestService";
import EditModal from "./EditModal";

const ManageContent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState({});

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [total, setTotal] = useState();

  const [totalCounts, setTotalCounts] = useState([]);
  const totalBlogs =
    (totalCounts.length > 0 &&
      totalCounts.find((data) => data._id === "Blog")) ||
    0;
  const totalEvents =
    (totalCounts.length > 0 &&
      totalCounts.find((data) => data._id === "Event")) ||
    0;
  const totalNews =
    (totalCounts.length > 0 &&
      totalCounts.find((data) => data._id === "News")) ||
    0;

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/post?page=${
        page ? page : 1
      }&perPage=10`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setData(res.data.docs);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    get(`/post/count`).then((res) => {
      setTotalCounts(res);
    });
  };

  const getTypePostTable = (type) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/post?type=${type}&perPage=1000`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setData(res.data.docs);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/post/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "postType",
      key: "postType",
    },
    {
      title: "Publisher",
      dataIndex: "user.name",
      key: "user.name",
      render: (_, data) => <p>{data.user && data.user.name}</p>,
    },
    {
      title: "Publish Date",
      dataIndex: "publishDate",
      key: "publishDate",
      render: (_, data) => (
        <p>{data.publishDate && data.publishDate.substring(0, 10)}</p>
      ),
    },
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setViewModal(true);
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setEditModal(true);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => deletePost(data._id)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={2} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Manage Content</h1>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <FirstCard
                onClick={() => getTypePostTable("Blog")}
                heading="Blogs"
                count={totalBlogs.count}
              />
            </div>
            <div style={{ flex: 1 }}>
              <FirstCard
                onClick={() => getTypePostTable("News")}
                heading="News"
                count={totalNews.count}
              />
            </div>
            <div style={{ flex: 1 }}>
              <FirstCard
                onClick={() => getTypePostTable("Event")}
                heading="Events"
                count={totalEvents.count}
              />
            </div>
          </div>
          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: true,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        destroyOnClose
        closable
        onCancel={() => {
          setViewModal(false);
          setViewData({});
        }}
        visible={viewModal}
      >
        <ViewModal data={viewData} />
      </Modal>
      <Modal
        footer={null}
        destroyOnClose
        closable
        onCancel={() => {
          setEditModal(false);
          setViewData({});
        }}
        visible={editModal}
      >
        <EditModal
          data={viewData}
          onCancel={() => setEditModal(false)}
          fetchData={getPostTable}
        />
      </Modal>
    </>
  );
};

export default ManageContent;
