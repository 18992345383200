import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu, Modal, Button } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import "./styles.css";
import { MoreOutlined } from "@ant-design/icons";
import { deleteService } from "../../services/RestService";
import EditModal from "./EditModal";

const Offices = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewData, setViewData] = useState({});
  const [action, setAction] = useState();
  const [editModal, setEditModal] = useState(false);
  const [total, setTotal] = useState();

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/election-center?page=${
        page ? page : 1
      }&perPage=10`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.data.docs);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/election-center/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, data) => (
        <p>{data.createdAt && data.createdAt.substring(0, 10)}</p>
      ),
    },
    //{
    //  title: 'Stations',
    //  dataIndex: 'stations',
    //  key: 'stations',
    //  render: (_, data) => <p>{data.stations.map((st) => st.name )}</p>
    //},
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setEditModal(true);
            setAction("view");
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setViewData(data);
            setEditModal(true);
            setAction("edit");
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => deletePost(data._id)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={8} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Offices</h1>
            <Button
              onClick={() => {
                setViewData(data);
                setEditModal(true);
                setAction("add");
              }}
              type="primary"
            >
              Add New Office
            </Button>
          </div>
          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: true,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        destroyOnClose
        closable
        onCancel={() => {
          setEditModal(false);
          setViewData({});
        }}
        visible={editModal}
      >
        <EditModal
          action={action}
          data={viewData}
          onCancel={() => setEditModal(false)}
          fetchData={getPostTable}
        />
      </Modal>
    </>
  );
};

export default Offices;
