import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Avatar,
  Row,
  Col,
  Popover,
  Empty,
  Badge,
  Input,
} from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import "./styles.css";
import {
  DeleteOutlined,
  EyeOutlined,
  MailOutlined,
  MoreOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { deleteService, patch } from "../../services/RestService";
import { useHistory } from "react-router";
import TextArea from "../../components/TextArea";
import { openNotification } from "../../helpers";
import XLSX from "xlsx";
import $ from "jquery";

const Observer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [search, setsearch] = useState("");
  const [remarks, setRemarks] = useState("");
  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [message, setMessage] = useState();

  const [viewModal, setViewModal] = useState(false);

  const [viewBulkModal, setBulkViewModal] = useState(false);
  const [bulkRemarks, setBulkRemarks] = useState("");

  const nonApproveData =
    (data &&
      data.filter(
        (value) => value.isAdmin === false && value.approved === false
      )) ||
    [];
  const approveData =
    (data &&
      data.filter(
        (value) => value.isAdmin === false && value.approved === true
      )) ||
    [];

  const searchData =
    (approveData &&
      approveData.filter(
        (sd) =>
          sd.name.toString().toLowerCase().includes(search) ||
          sd.email.toString().toLowerCase().includes(search)
      )) ||
    [];

  const getPostTable = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.muthanaalsamari.com/api/auth/users?userType=observer&perPage=1000",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.data.docs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/auth/users/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const sendRemarks = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      remarks: [
        {
          text: remarks,
        },
      ],
    };

    patch(`/auth/update/${userId}`, data, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        openNotification("Remarks Sent Successfully");
        setRemarks("");
        setUserId();
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const sendMessage = (user) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      remarks: [
        {
          text: message,
        },
      ],
    };

    patch(`/auth/update/${user}`, data, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        openNotification("Message Sent Successfully");
        setMessage("");
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const content = (user) => {
    return (
      <div>
        {user.userToAdminRemarks.length < 1 ? (
          <Empty />
        ) : (
          <div>
            {user.userToAdminRemarks.map((data, index) => (
              <p key={index}>● {data.text}</p>
            ))}
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          <Input
            style={{ height: 30 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <SendOutlined
            style={{ marginLeft: 5, fontSize: 25, cursor: "pointer" }}
            onClick={() => sendMessage(user._id)}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      width: "10%",
      render: (_, data) => <Avatar size="small" src={data.image && data} />,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      width: "80%",
      render: (_, data) => (
        <p>
          <span style={{ color: "green" }}>{data.name}</span> is waiting for
          your request to be approved!
        </p>
      ),
    },

    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
      render: (_, data) => (
        <div style={{ display: "flex" }}>
          <Avatar size="small" src={data.image && data.image} />
          <p style={{ marginLeft: 10 }}>{data.name}</p>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Locations",
      dataIndex: "location",
      key: "location",
      render: (_, data) => <p>{data.location}</p>,
    },
    {
      title: "Election Center",
      dataIndex: "electionCenter",
      key: "electionCenter",
      render: (_, data) => <p>{data.electionCenter}</p>,
    },

    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteOutlined
            onClick={() => deletePost(data._id)}
            style={{ color: "red", cursor: "pointer", fontSize: 25 }}
          />
          <EyeOutlined
            onClick={() => {
              history.push(`viewobserver?id=${data._id}&m=view`);
            }}
            style={{
              color: "green",
              cursor: "pointer",
              fontSize: 25,
              marginLeft: 10,
            }}
          />
          <Popover
            content={() => content(data)}
            title="Messages"
            trigger="click"
          >
            <Badge size="small" count={data.userToAdminRemarks.length}>
              <MailOutlined
                style={{
                  color: "black",
                  cursor: "pointer",
                  fontSize: 25,
                  marginLeft: 10,
                }}
              />
            </Badge>
            {/* <Button style={{ marginLeft: 10, backgroundColor: 'red', color:'white'}}>Messages</Button> */}
          </Popover>
          <Button
            onClick={() => {
              setUserId(data._id);
              setViewModal(true);
            }}
            style={{ marginLeft: 10 }}
            type="dashed"
          >
            Remarks
          </Button>
        </div>
      ),
    },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            history.push(`viewobserver?id=${data._id}`);
          }}
        >
          View
        </Menu.Item>
      </Menu>
    );
  };

  const handleUserSelected = (employee, isSelected) => {
    let membs = [...members];

    // if checkbox selected add the user to array
    if (isSelected) membs.push(employee);
    // if checkbox unselected remove the user from array
    else membs = membs.filter((mem) => mem._id !== employee._id);

    setMembers(membs);
  };

  const handleAllUsersSelected = (isSelected, _, changedUsers) => {
    let membs = [...members];

    // if checkbox selected add all user on current page to array
    if (isSelected) membs.push(...changedUsers.map((row) => row));
    // if checkbox unselected remove all selected users from array
    else
      membs = membs.filter(
        (mem) => !changedUsers.map((row) => row._id).includes(mem._id)
      );

    setMembers(membs);
  };

  const sendBulkRemarks = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      remarks: [
        {
          text: bulkRemarks,
        },
      ],
    };
    members.forEach((element) => {
      patch(`/auth/update/${element._id}`, data, options)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
    setLoading(false);
    openNotification("Remarks Sent Successfully");
    setBulkRemarks("");
    getPostTable();
  };

  console.log(members);

  const downloadfile = (oneData) => {
    var createXLSLFormatObj = [];

    /* XLS Head Columns */
    var xlsHeader = [
      "OBSERVER_NAME",
      "OBSERVER_LOCATION",
      "OBSERVER_PHONE",
      "OBSERVER_GENDER",
      "OBSERVER_EMPLOYMENT",
      "OBSERVER_EMAIL",
      "OBSERVER_DEGREE",
      "OBSERVER_DOB",
      "OBSERVER_IMAGE",
      "OBSERVER_ELECTIONCENTER",
    ];

    /* XLS Rows Data */

    var xlsRows = [];

    if (oneData) {
      oneData &&
        oneData.map((oneData, i) =>
          xlsRows.push({
            OBSERVER_NAME: oneData.name,
            OBSERVER_LOCATION: oneData.location,
            OBSERVER_PHONE: oneData.phone,
            OBSERVER_GENDER: oneData.gender,
            OBSERVER_EMPLOYMENT: oneData.employment,
            OBSERVER_EMAIL: oneData.email,
            OBSERVER_DEGREE: oneData.degreeTitle,
            OBSERVER_DOB: oneData.dob,
            OBSERVER_IMAGE: oneData.image,
            OBSERVER_ELECTIONCENTER: oneData.electionCenter,
          })
        );
    } else {
      const fullData = approveData || [];

      fullData.map((data, index) =>
        xlsRows.push({
          OBSERVER_NAME: data.name,
          OBSERVER_LOCATION: data.location,
          OBSERVER_PHONE: data.phone,
          OBSERVER_GENDER: data.gender,
          OBSERVER_EMPLOYMENT: data.employment,
          OBSERVER_EMAIL: data.email,
          OBSERVER_DEGREE: data.degreeTitle,
          OBSERVER_DOB: data.dob,
          OBSERVER_IMAGE: data.image,
          OBSERVER_ELECTIONCENTER: data.electionCenter,
        })
      );
    }

    createXLSLFormatObj.push(xlsHeader);

    $.each(xlsRows, function (index, value) {
      var innerRowData = [];
      $.each(value, function (ind, val) {
        innerRowData.push(val);
      });
      createXLSLFormatObj.push(innerRowData);
    });

    /* File Name */
    var filename = "ObserverReport.xlsx";

    /* Sheet Name */
    var ws_name = "Report";

    if (typeof console !== "undefined") console.log(new Date());
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* Write workbook and Download */
    if (typeof console !== "undefined") console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== "undefined") console.log(new Date());
  };

  return (
    <>
      <NavBar getPostTable={getPostTable} />
      <div style={{ display: "flex" }}>
        <Sidebar active={3} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Observers</h1>
          </div>
          <div>
            <Row gutter={[10, 10]}>
              <Col md={15} lg={15} xl={15}>
                <div
                  className="table-card"
                  style={{ marginTop: 20, height: 400 }}
                >
                  <h2 style={{ marginTop: 10 }}>Observer Request </h2>
                  <Table
                    showHeader={false}
                    scroll={{ y: 300 }}
                    size="small"
                    loading={loading}
                    columns={columns}
                    dataSource={nonApproveData}
                  />
                </div>
              </Col>
              <Col md={9} lg={9} xl={9}>
                <div
                  className="table-card stat-card"
                  style={{ marginTop: 20, height: 400 }}
                >
                  <h2 style={{ marginTop: 30, textAlign: "center" }}>
                    Registered Observer
                  </h2>
                  <h2
                    className="longShadow"
                    style={{
                      color: "green",
                      fontSize: 50,
                      textAlign: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {approveData.length}
                  </h2>
                  <h2 style={{ marginTop: 60, textAlign: "center" }}>
                    Pending Observer
                  </h2>
                  <h2
                    className="longShadow"
                    style={{
                      color: "green",
                      fontSize: 50,
                      textAlign: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {nonApproveData.length}
                  </h2>
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 style={{ marginTop: 10, marginBottom: 20 }}>
                  Current Observer
                </h2>
                <div>
                  {members.length > 0 && (
                    <Button
                      style={{ marginRight: 10 }}
                      onClick={() => setBulkViewModal(true)}
                      type="primary"
                    >
                      Send Remarks
                    </Button>
                  )}
                  {members.length > 0 && (
                    <Button
                      style={{ marginRight: 10 }}
                      onClick={() => downloadfile(members)}
                      type="primary"
                    >
                      Download
                    </Button>
                  )}
                  {
                    <Button onClick={() => downloadfile()} type="danger">
                      Download All
                    </Button>
                  }
                </div>
              </div>
              <Input
                placeholder="search observer by name or email"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
              <Table
                rowSelection={{
                  selectedRowKeys: members._id,
                  onSelect: handleUserSelected,
                  onSelectAll: handleAllUsersSelected,
                }}
                rowKey="_id"
                size="small"
                loading={loading}
                columns={columns2}
                dataSource={searchData}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Add Remarks"
        okText="Send"
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        onOk={() => {
          setViewModal(false);
          sendRemarks();
        }}
      >
        <TextArea
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          label="Remarks"
        />
      </Modal>
      <Modal
        title="Add Remarks"
        okText="Send"
        visible={viewBulkModal}
        onCancel={() => setBulkViewModal(false)}
        onOk={() => {
          setBulkViewModal(false);
          sendBulkRemarks();
        }}
      >
        <TextArea
          value={bulkRemarks}
          onChange={(e) => setBulkRemarks(e.target.value)}
          label="Remarks"
        />
      </Modal>
    </>
  );
};

export default Observer;
