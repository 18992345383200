import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu, Modal, Image } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import { MoreOutlined } from "@ant-design/icons";
import { deleteService, get, post, patch } from "../../services/RestService";
import InputBox from "../../components/InputBox";
import UploadImage from "../../components/UploadImage";
import SelectBox from "../../components/Select";

const Media = () => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [file, setFile] = useState();
  const [type, setType] = useState();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [id, setId] = useState();
  const [total, setTotal] = useState();

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    get(`/media?page=${page ? page : 1}&perPage=10`, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res.docs);
        setTotal(res.totalDocs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/media/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Media",
      dataIndex: "file",
      key: "file",
      render: (_, data) =>
        data.type && data.type === "image" ? (
          <div style={{ display: "flex" }}>
            <Image
              style={{ width: 200, height: 200, objectFit: "cover" }}
              src={data.file && data.file}
            />
          </div>
        ) : (
          <a href={data.url}>{data.url}</a>
        ),
    },
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const uploadData = () => {
    setLoading(true);
    const data = new FormData();
    data.append("title", title);
    data.append("type", type);
    type === "image" ? data.append("mediaFile", file) : data.append("url", url);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    post("/media", data, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
        setTitle("");
        setType();
        setUrl();
        setFile(null);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const editUploadData = () => {
    setLoading(true);
    const data = new FormData();
    data.append("title", title);
    type === "image" ? data.append("mediaFile", file) : data.append("url", url);
    data.append("type", type);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/media/${id}`, data, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setTitle("");
        setType();
        setUrl();
        setFile(null);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const selectData = [
    { title: "Image", value: "image" },
    { title: "Video", value: "video" },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setTitle(data.title);
            setType(data.type);
            setUrl(data.url);
            setFile(data.mediaFile);
            setId(data._id);
            setEditModal(true);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => deletePost(data._id)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={9} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Media</h1>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <InputBox
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                label="Title"
                type="text"
              />

              <div style={{ marginLeft: 20 }}>
                <SelectBox
                  data={selectData}
                  width={110}
                  label="Type"
                  value={type}
                  onChange={(e) => setType(e)}
                />
              </div>
              {type === "image" && (
                <div
                  style={{
                    width: 100,
                    marginLeft: 20,
                    marginRight: 20,
                    display: "flex",
                  }}
                >
                  <UploadImage setImage={setFile} image={file} label="Upload" />
                  <div style={{ marginTop: 50, marginLeft: 20 }}>
                    <button
                      onClick={() => uploadData()}
                      className="create-btn"
                      type="submit"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              )}
            </div>

            {type === "video" && (
              <div style={{ display: "flex" }}>
                <InputBox
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  label="Video Url"
                  type="text"
                />
                <div style={{ marginTop: 50, marginLeft: 20 }}>
                  <button
                    onClick={() => uploadData()}
                    className="create-btn"
                    type="submit"
                  >
                    Upload
                  </button>
                </div>
              </div>
            )}

            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: false,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        footer={false}
        visible={editModal}
        onCancel={() => setEditModal(false)}
      >
        <h1 style={{ textAlign: "center" }}>Edit Media</h1>
        <div>
          <InputBox
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Title"
            type="text"
          />

          <div>
            <SelectBox
              data={selectData}
              width={110}
              label="Type"
              value={type}
              onChange={(e) => setType(e)}
            />
          </div>

          {type === "image" && (
            <div style={{ width: 100, display: "flex" }}>
              <UploadImage setImage={setFile} image={file} label="Upload" />
            </div>
          )}

          {type === "video" && (
            <div style={{ display: "flex" }}>
              <InputBox
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                label="Video Url"
                type="text"
              />
            </div>
          )}

          <div style={{ marginTop: 50 }}>
            <button
              onClick={() => {
                editUploadData();
                setEditModal(false);
                setId(null);
              }}
              className="create-btn"
              type="submit"
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Media;
