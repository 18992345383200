import React, { useEffect, useState } from "react";
import NavBar from "../../components/navbar";
import { CHART_TYPES, CHART_DATASET_OPTIONS } from "../../helpers";
import { Empty, Input, Modal, Space, Select, Table } from "antd";
import Charts from "../../components/ChartsGroup";
import Sidebar from "../../components/sidebar/sideBar";
import "./styles.css";
import { get } from "../../services/RestService";
import XLSX from "xlsx";
import $ from "jquery";

const { Search } = Input;
const { Option } = Select;

const Home = () => {
  const [data, setData] = useState([]);
  const [stationData, setStationData] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [cData, setCData] = useState(false);
  const [voteData, setVoteData] = useState([]);
  const [type, setType] = useState("bar");
  const [stype, setSType] = useState("line");
  const chartTypes = CHART_TYPES;

  const dataSetOptions = {
    label: "Votes Per Candidate",
    ...CHART_DATASET_OPTIONS,
  };

  const cdataSetOptions = {
    label: "Votes Per Election Center",
    ...CHART_DATASET_OPTIONS,
  };

  const sdataSetOptions = {
    label: "Votes Per Election Station",
    ...CHART_DATASET_OPTIONS,
  };

  const getReportData = () => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    get("/voting-report/dashboard/stats", options)
      .then((res) => {
        //setData(res)
        setVoteData(res);
        setData({
          labels: res.map((c) => c.candidate[0].fullName),
          datasets: [
            {
              ...dataSetOptions,
              data: res.map((c) => c.votes),
            },
          ],
        });
        console.log("new data =====>", data);
      })
      .catch((err) => {
        console.log(err);
      });

    get("/voting-report/dashboard/statPerCenter", options)
      .then((res) => {
        setCenterData({
          labels: res.map((c) => c.electionCenter),
          datasets: [
            {
              ...cdataSetOptions,
              data: res.map((c) => c.votes),
            },
          ],
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    get("/voting-report/dashboard/statPerStation", options)
      .then((res) => {
        setStationData({
          labels: res.map((c) => c.electionStation),
          datasets: [
            {
              ...sdataSetOptions,
              data: res.map((c) => c.votes),
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReportData();
  }, []);

  const downloadfile = () => {
    var createXLSLFormatObj = [];

    /* XLS Head Columns */
    var xlsHeader = [
      "OBSERVER_NAME",
      "OBSERVER_LOCATION",
      "OBSERVER_PHONE",
      "OBSERVER_GENDER",
      "OBSERVER_EMPLOYMENT",
      "OBSERVER_EMAIL",
      "OBSERVER_DEGREE",
      "OBSERVER_DOB",
      "OBSERVER_IMAGE",
      "CIRCLE",
      "ELECTION_CENTER",
      "ELECTION_STATION",
      "CANDIDATE_NAME",
      "CANDIDATE_NO",
      "CANDIDATE_CITY",
      "VOTES",
    ];

    /* XLS Rows Data */

    var xlsRows = [];

    cData &&
      cData.map((oneData, i) =>
        xlsRows.push({
          OBSERVER_NAME:
            oneData.user[0] && oneData.user[0] && oneData.user[0].name,
          OBSERVER_LOCATION:
            oneData.user[0] && oneData.user[0] && oneData.user[0].location,
          OBSERVER_PHONE:
            oneData.user[0] && oneData.user[0] && oneData.user[0].phone,
          OBSERVER_GENDER:
            oneData.user[0] && oneData.user[0] && oneData.user[0].gender,
          OBSERVER_EMPLOYMENT:
            oneData.user[0] && oneData.user[0] && oneData.user[0].employment,
          OBSERVER_EMAIL:
            oneData.user[0] && oneData.user[0] && oneData.user[0].email,
          OBSERVER_DEGREE:
            oneData.user[0] && oneData.user[0] && oneData.user[0].degreeTitle,
          OBSERVER_DOB:
            oneData.user[0] && oneData.user[0] && oneData.user[0].dob,
          OBSERVER_IMAGE:
            oneData.user[0] && oneData.user[0] && oneData.user[0].image,
          CIRCLE: oneData.circle && oneData.circle,
          ELECTION_CENTER: oneData.electionCenter && oneData.electionCenter,
          ELECTION_STATION: oneData.electionStation && oneData.electionStation,
          CANDIDATE_NAME:
            oneData.candidates && oneData.candidates.candidate[0].fullName,
          CANDIDATE_NO: oneData.candidates && oneData.candidates.candidateNo,
          CANDIDATE_CITY:
            oneData.candidates &&
            oneData.candidates.candidate &&
            oneData.candidates.candidate[0].city,
          VOTES: oneData.candidates && oneData.candidates.votesNos,
        })
      );

    //const chartData = []
    //data && data.map((data, i) => chartData.push({name: `${data.candidate[0].fullName}`, votes: data.votes, candidate_no: data.candidate[0].candidateNumber }))
    //
    //console.log(chartData)

    //chartData.map((data, index) => (
    //    xlsRows.push({CANDIDATE_NAME: data.name, CANDIDATE_NO: data.candidate_no , VOTES: data.votes  })
    //))

    createXLSLFormatObj.push(xlsHeader);

    $.each(xlsRows, function (index, value) {
      var innerRowData = [];
      $.each(value, function (ind, val) {
        innerRowData.push(val);
      });
      createXLSLFormatObj.push(innerRowData);
    });

    /* File Name */
    var filename = "Report.xlsx";

    /* Sheet Name */
    var ws_name = "Report";

    if (typeof console !== "undefined") console.log(new Date());
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* Write workbook and Download */
    if (typeof console !== "undefined") console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== "undefined") console.log(new Date());
  };

  const downloadVotesFile = () => {
    var createXLSLFormatObj = [];

    /* XLS Head Columns */
    var xlsHeader = ["CANDIDATE_NAME", "CANDIDATE_NO", " VOTES"];

    /* XLS Rows Data */

    var xlsRows = [];

    const chartData = [];
    voteData &&
      voteData.map((data, i) =>
        chartData.push({
          name: `${data.candidate[0].fullName}`,
          votes: data.votes,
          candidate_no: data.candidate[0].candidateNumber,
        })
      );

    console.log(chartData);
    chartData.map((data, index) =>
      xlsRows.push({
        CANDIDATE_NAME: data.name,
        CANDIDATE_NO: data.candidate_no,
        VOTES: data.votes,
      })
    );

    createXLSLFormatObj.push(xlsHeader);

    $.each(xlsRows, function (index, value) {
      var innerRowData = [];
      $.each(value, function (ind, val) {
        innerRowData.push(val);
      });
      createXLSLFormatObj.push(innerRowData);
    });

    /* File Name */
    var filename = "Report.xlsx";

    /* Sheet Name */
    var ws_name = "Report";

    if (typeof console !== "undefined") console.log(new Date());
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* Write workbook and Download */
    if (typeof console !== "undefined") console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== "undefined") console.log(new Date());
  };

  const onSearch = (e) => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get(`/voting-report?candidateNo=${e}`, options).then((res) => {
      setCData(res.docs);
      setViewModal(true);
      console.log(res.docs);
    });
  };

  const columns = [
    {
      title: "Observer Name",
      dataIndex: "user",
      key: "user",
      render: (_, data) => (
        <p>{data.user[0] && data.user[0] && data.user[0].name}</p>
      ),
    },
    {
      title: "Observer Email",
      dataIndex: "user",
      key: "user",
      render: (_, data) => (
        <p>{data.user[0] && data.user[0] && data.user[0].email}</p>
      ),
    },
    {
      title: "Observer phone",
      dataIndex: "user",
      key: "user",
      render: (_, data) => (
        <p>{data.user[0] && data.user[0] && data.user[0].phone}</p>
      ),
    },
    {
      title: "Observer Location",
      dataIndex: "user",
      key: "user",
      render: (_, data) => (
        <p>{data.user[0] && data.user[0] && data.user[0].location}</p>
      ),
    },
    {
      title: "Candidate Name",
      dataIndex: "user",
      key: "user",
      render: (_, data) => (
        <p>{data.candidates && data.candidates.candidate[0].fullName}</p>
      ),
    },
    {
      title: "Candidate No",
      dataIndex: "user",
      key: "user",
      render: (_, data) => <p>{data.candidates.candidateNo}</p>,
    },
    {
      title: "Election Station",
      dataIndex: "user",
      key: "user",
      render: (_, data) => <p>{data.electionStation}</p>,
    },
    {
      title: "Election Center",
      dataIndex: "user",
      key: "user",
      render: (_, data) => <p>{data.electionCenter}</p>,
    },
    {
      title: "Votes No",
      dataIndex: "user",
      key: "user",
      render: (_, data) => <p>{data.candidates && data.candidates.votesNos}</p>,
    },
  ];

  return (
    <div>
      <NavBar getReportData={getReportData} />
      <div style={{ display: "flex" }}>
        <Sidebar active={0} />
        <div className="right-sec">
          <div className="headingBar">
            <h1>Dashboard</h1>
          </div>

          <div>
            <Search
              size="large"
              placeholder="Search Candidate"
              enterButton
              onSearch={onSearch}
              style={{ width: 400, marginBottom: 20 }}
            />
          </div>

          <>
            <div className="mb-20">
              <p className="mb-5">Chart Type</p>
              <Select
                value={type}
                style={{ width: 150 }}
                onChange={(e) => setType(e)}
              >
                {chartTypes.map((type) => (
                  <Option key={type.value} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className="small-shadow"
              style={{
                backgroundColor: "white",
                borderRadius: 5,
                padding: 5,
                marginTop: 5,
                scrollBehavior: "auto",
              }}
            >
              <Charts type={type} data={data} />
            </div>
          </>

          <>
            <div style={{ marginTop: 10 }} className="mb-20">
              <p className="mb-5 mt-10">Chart Type</p>
              <Select
                value={stype}
                style={{ width: 150 }}
                onChange={(e) => setSType(e)}
              >
                {chartTypes.map((type) => (
                  <Option key={type.value} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </div>

            <div style={{ display: "flex", marginTop: 20, width: "100%" }}>
              <div
                className="small-shadow"
                style={{ borderRadius: 15, width: "97%" }}
              >
                <Charts type={stype} data={centerData} />
              </div>
              <Space />
              <div
                className="small-shadow"
                style={{ borderRadius: 15, width: "97%" }}
              >
                <Charts type={stype} data={stationData} />
              </div>
            </div>
          </>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
            className="d-flex justify-content-center mt-5"
          >
            <button
              onClick={() => downloadVotesFile()}
              style={{ border: "none", outline: "none", cursor: "pointer" }}
              className="btn bg-red d-btn"
            >
              Download Result
            </button>
          </div>
        </div>
      </div>
      <Modal
        width={900}
        title="Candidate Result"
        visible={viewModal}
        onOk={downloadfile}
        onCancel={() => {
          setViewModal(false);
          setCData([]);
        }}
        okText="download"
      >
        {cData.length < 1 ? (
          <Empty />
        ) : (
          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  pageSize: 20,
                  showQuickJumper: true,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                rowKey="_id"
                scroll={{ x: true }}
                columns={columns}
                dataSource={cData}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Home;
