import React, { useState, useEffect, useRef } from "react";
import { Form, notification, Upload } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import SelectBox from "../../components/Select";
import { FullPageLoader } from "../../components/Loaders";
import { get, patch } from "../../services/RestService";
import JoditEditor from "jodit-react";

const ManagePages = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDiscription] = useState("");
  const [style, setStyle] = useState("");
  const [duration, setDuration] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [pageType, setPageType] = useState();
  const [id, setId] = useState();
  const [selectedFile, setSelectedFile] = useState([]);

  const [pageData, setPageData] = useState([]);

  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const reset = () => {
    setTitle("");
    setCategory("");
    setDiscription("");
    setImage(null);
    setStyle("");
    setPageType();
    setDuration();
    setSelectedFile([]);
  };

  const getData = () => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get("/page", options)
      .then((res) => {
        setLoading(false);
        setPageData(res.docs);
        console.log(res.docs);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = (values) => {
    setLoading(true);
    const data = new FormData();
    data.append("title", title);
    data.append("contents", description);
    data.append("changeDuration", duration);
    data.append("bannerStyle", style);
    if (selectedFile) {
      selectedFile.forEach((file) => {
        data.append("images", file.originFileObj);
      });
    }

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/page/${id}`, data, options)
      .then((res) => {
        setLoading(false);
        getData();
        openNotification("Data Updated Successfully");
        reset();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const selectData = [];
  pageData &&
    pageData.map((data, i) =>
      selectData.push({ title: data.pageType, value: data._id, key: i })
    );

  const bannerS = [
    { title: "Slideshow", value: "Slideshow" },
    { title: "Picture", value: "Picture" },
  ];

  const setData = (e) => {
    const data = pageData.find((d) => d._id === e);
    setTitle(data.title);
    setStyle(data.bannerStyle);
    setDiscription(data.contents);
    setDuration(data.changeDuration);
    setId(data._id);
    setPageType(data.pageType);
    const imgList = [];
    data.images.map((img, i) =>
      imgList.push({
        uid: i,
        name: `image${i}.png`,
        status: "done",
        url: img,
      })
    );
    setSelectedFile(imgList);
  };

  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fileSelected = ({ file, fileList: newFileList }) => {
    setSelectedFile(newFileList);
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={10} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Manage Pages</h1>
          </div>

          {loading ? (
            <FullPageLoader />
          ) : (
            <Form onFinish={onSubmit}>
              <div>
                <SelectBox
                  width={250}
                  data={selectData}
                  label="Select Page"
                  onChange={(e) => setData(e)}
                />
              </div>

              <div style={{ display: "flex" }}>
                {pageType !== "home-page" && (
                  <InputBox
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    label="Title"
                    type="text"
                  />
                )}
                <div style={{ marginLeft: 20 }}>
                  <SelectBox
                    value={style}
                    width={150}
                    data={bannerS}
                    label="Banner Style"
                    onChange={(e) => setStyle(e)}
                  />
                </div>

                {style === "Slideshow" && (
                  <div style={{ marginLeft: 20 }}>
                    <InputBox
                      width={100}
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      label="Duration"
                      type="number"
                    />
                  </div>
                )}
              </div>

              <div style={{ marginTop: 30 }}>
                <p
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    fontWeight: "bold",
                  }}
                >
                  Upload Images
                </p>
                <Upload
                  customRequest={dummyRequest}
                  listType="picture-card"
                  fileList={selectedFile}
                  onChange={fileSelected}
                >
                  {"+ Upload"}
                </Upload>
              </div>

              {pageType !== "home-page" && (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: 20,
                      marginTop: 10,
                    }}
                  >
                    Content
                  </p>
                  <JoditEditor
                    ref={editor}
                    value={description}
                    config={config}
                    tabIndex={5} // tabIndex of textarea
                    onBlur={(newContent) => setDiscription(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                </div>
              )}

              <div className="flex-center">
                <div className="btns-wrap">
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                  <button className="create-btn" type="submit">
                    Update Page
                  </button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default ManagePages;
