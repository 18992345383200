import React, { useEffect, useState } from "react";
import { notification, Input, Select, Button, Spin, Empty } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import { post, get, patch } from "../../services/RestService";
import { useQueryParams } from "../../helpers";
import { useHistory } from "react-router";
const { Option } = Select;
const AddEditSurvey = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(false);
  const [participant, setParticipant] = useState([]);
  const [item, setItem] = useState([
    { question: "", questionType: "multiple", answerOptions: [] },
  ]);
  const query = useQueryParams();
  const id = query.get("id");
  const method = query.get("m");
  const history = useHistory();

  const handleRemoveClick = (index) => {
    const list = [...item];
    list.splice(index, 1);
    setItem(list);
  };

  console.log(participant);

  // handle  price click event of the Add button
  const handleAddClick = () => {
    setItem([
      ...item,
      { question: "", questionType: "multiple", answerOptions: [] },
    ]);
  };

  const handleQuestionInputChange = (e, index) => {
    const list = [...item];
    const name = "question";
    list[index][name] = e.target.value;
    setItem(list);
  };

  const handleAnswerInputChange = (e, a, index) => {
    const list = [...item];
    const name = "answerOptions";
    list[index][name][a] = e.target.value;
    setItem(list);
  };

  const onSubmit = (values) => {
    setLoading(true);

    console.log(item);
    const data = {
      title: title,
      status: false,
      createDate: new Date(),
      items: item,
      surveyorType: type,
      type: type,
    };

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    post("/survey", data, options)
      .then((res) => {
        setLoading(false);
        openNotification("Data Uploaded Successfully");
        history.push("/surveys");
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const onEdit = (values) => {
    setLoading(true);

    console.log(item);
    const data = {
      title: title,
      status: false,
      createDate: new Date(),
      items: item,
      surveyorType: type,
      type: type,
    };

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/survey/${id}`, data, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Uploaded Successfully");
        history.push("/surveys");
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const viewData = () => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get(`/survey/${id}`, options).then((res) => {
      console.log("data", res);
      setTitle(res.title);
      setItem(res.items);
      setType(res.surveyorType);
      setParticipant(res.participants);
    });
  };

  useEffect(() => {
    if (id) viewData();
  }, [id]);

  console.log(method);

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={4} />
        {loading ? (
          <Spin />
        ) : (
          <div className="right-sec">
            <div className="headingBar">
              <h1 className="fw700 fs36 lh36">
                {method === "result"
                  ? "View Survey Result"
                  : method === "view"
                  ? "View Survey"
                  : method === "edit"
                  ? "Edit Survey"
                  : "Add Survey"}
              </h1>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 20 }}>
                  <h3 style={{ marginBottom: 10 }}>Title</h3>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div style={{ marginRight: 20 }}>
                  <h3 style={{ marginBottom: 10 }}>Type</h3>
                  <Select
                    style={{ height: 37, width: 150 }}
                    value={type}
                    defaultValue="Select"
                    onChange={(e) => setType(e)}
                  >
                    <Option value="Observers">Observers</Option>
                    <Option value="Public">Public</Option>
                  </Select>
                </div>
              </div>

              {item.map((x, i) => (
                <>
                  <div style={{ display: "flex", marginTop: 30 }}>
                    <div style={{ marginRight: 20 }}>
                      <h3 style={{ marginBottom: 10 }}>Question</h3>
                      <Input
                        value={x.question}
                        onChange={(e) => handleQuestionInputChange(e, i)}
                      />
                    </div>
                    <div style={{ marginRight: 20 }}>
                      <h3 style={{ marginBottom: 10 }}>Answer 1</h3>
                      <Input
                        value={x.answerOptions[0]}
                        onChange={(e) => handleAnswerInputChange(e, 0, i)}
                      />
                    </div>
                    <div style={{ marginRight: 20 }}>
                      <h3 style={{ marginBottom: 10 }}>Answer 2</h3>
                      <Input
                        value={x.answerOptions[1]}
                        onChange={(e) => handleAnswerInputChange(e, 1, i)}
                      />
                    </div>
                    <div style={{ marginRight: 20 }}>
                      <h3 style={{ marginBottom: 10 }}>Answer 3</h3>
                      <Input
                        value={x.answerOptions[2]}
                        onChange={(e) => handleAnswerInputChange(e, 2, i)}
                      />
                    </div>
                    <div></div>
                  </div>
                  {(method === "add" || method === "edit") && (
                    <Button
                      style={{ marginTop: 36 }}
                      onClick={() => handleRemoveClick()}
                    >
                      remove
                    </Button>
                  )}
                </>
              ))}

              {method === "result" && (
                <>
                  <h1
                    className="fw700 fs36 lh36"
                    style={{ marginTop: 30, marginBottom: 30 }}
                  >
                    Result
                  </h1>
                  {participant.length < 1 ? (
                    <Empty />
                  ) : (
                    <div>
                      {participant.map((data, index) => (
                        <div>
                          <p key={index}>
                            {index + 1}: {"Q:"}
                            {data.question && data.question} {"A:"}
                            {data.answer && data.answer}
                          </p>
                          {type === "Observers" && (
                            <p>
                              {"by"} {data.user.name}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            {(method === "add" || method === "edit") && (
              <Button
                onClick={() => handleAddClick()}
                style={{ marginTop: 30 }}
              >
                Add More Question
              </Button>
            )}

            {(method === "add" || method === "edit") && (
              <Button
                type="primary"
                onClick={() => {
                  method && method === "edit" ? onEdit() : onSubmit();
                }}
                style={{ marginTop: 70, float: "right" }}
              >
                Submit
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AddEditSurvey;
