import { Image } from "antd";
import React, { useState, useRef } from "react";
import { Form, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/Select";
import UploadImage from "../../components/UploadImage";
import { patch } from "../../services/RestService";
import JoditEditor from "jodit-react";

const EditModal = ({ data, onCancel, fetchData }) => {
  const [title, setTitle] = useState(data.title);
  const [category, setCategory] = useState(data.postType);
  const [description, setDiscription] = useState(data.description);
  const [date, setDate] = useState(data.publishDate);
  const [image, setImage] = useState();
  const [eventLocation, setEventLocation] = useState(data.eventLocation);
  const [imageUrl, setImageUrl] = useState(data.image && data.image);
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const onSubmit = (values) => {
    setLoading(true);
    const dataForm = new FormData();
    dataForm.append("title", title);
    dataForm.append("postType", category);
    dataForm.append("publishDate", date);
    dataForm.append("description", description);
    category === "Event" && dataForm.append("eventLocation", eventLocation);
    image && dataForm.append("image", image);

    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    patch(`/post/${data._id}`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Data Edited Successfully");
        onCancel();
        fetchData();
      })
      .catch((err) => {
        openNotification("Fill all the Fields");
        setLoading(false);
        console.log(err.data);
      });
  };

  const openNotification = (text) => {
    notification.open({
      message: text,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const selectData = [
    { value: "Blog", title: "Blogs" },
    { value: "Event", title: "Events" },
    { value: "News", title: "News" },
    { value: "Election Message", title: "Election Message" },
  ];

  return (
    <div style={{ padding: 10 }}>
      <h1 style={{ textAlign: "center" }}>Edit Post</h1>
      <Form onFinish={onSubmit}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <InputBox
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Title"
            type="text"
          />
          <Form.Item>
            <div style={{ marginLeft: 20 }}>
              <SelectBox
                width={180}
                data={selectData}
                value={category}
                label="Category"
                onChange={(e) => setCategory(e)}
              />
            </div>
          </Form.Item>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Image
            style={{
              width: "100%",
              height: 200,
              objectFit: "cover",
              alignSelf: "center",
            }}
            src={image ? URL.createObjectURL(image) : imageUrl}
          />
        </div>

        <div style={{ width: 100 }}>
          <UploadImage setImage={setImage} image={image} label="Upload" />
        </div>

        <Form.Item>
          <InputBox
            value={date && date.substring(0, 10)}
            onChange={(e) => setDate(e.target.value)}
            label="Date"
            type="date"
          />
        </Form.Item>

        {category === "Event" && (
          <InputBox
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
            label="Event Location"
            type="text"
          />
        )}

        <div>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>Description</p>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={5} // tabIndex of textarea
            onBlur={(newContent) => setDiscription(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </div>

        <div className="flex-center">
          <div className="btns-wrap">
            <button onClick={() => onCancel()} className="cancel-btn">
              Cancel
            </button>
            <button className="create-btn" type="submit">
              Upload
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditModal;
