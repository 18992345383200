import React from "react";
export default function TextArea({
  label,
  placeholder,
  hasButton,
  name,
  value,
  onChange,
}) {
  return (
    <>
      <div className="cst-textinput">
        <label className="fw600 fs24 lh48">{label}</label>
        <textarea
          value={value}
          onChange={onChange}
          name={name}
          rows={5}
        ></textarea>
      </div>{" "}
    </>
  );
}
