import React, { useState, useEffect } from "react";
import { Table, Dropdown, Menu, Button } from "antd";
import NavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sideBar";
import axios from "axios";
import "./styles.css";
import { MoreOutlined } from "@ant-design/icons";
import { deleteService } from "../../services/RestService";
import { useHistory } from "react-router";
import $ from "jquery";
import XLSX from "xlsx";

const Survey = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();

  console.log(data);

  const getPostTable = (page) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.muthanaalsamari.com/api/survey`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        setTotal(res.data.length);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPostTable();
  }, []);

  const deletePost = (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/survey/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        getPostTable();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data) => <p>{data.status ? "Active" : "Expired"}</p>,
    },
    {
      title: "Date Created",
      dataIndex: "createDate",
      key: "createDate",
      render: (_, data) => (
        <p>{data.createDate && data.createDate.substring(0, 10)}</p>
      ),
    },
    {
      title: "Surveyor Type",
      dataIndex: "surveyorType",
      key: "surveyorType",
    },
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const column2 = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Surveyor Type",
      dataIndex: "surveyorType",
      key: "surveyorType",
    },
    {
      title: "No of Responds",
      dataIndex: "participant",
      key: "participant",
      render: (_, data) => (
        <p>{data.participants && data.participants.length}</p>
      ),
    },
    {
      title: "Options",
      dataIndex: "_id",
      key: "_id",
      render: (_, data) => (
        <Dropdown overlay={() => menu2(data)} trigger={["click"]}>
          <MoreOutlined style={{ fontSize: 25, fontWeight: "bold" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            history.push(`addeditsurvey?id=${data._id}&m=view`);
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            history.push(`addeditsurvey?id=${data._id}&m=edit`);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => deletePost(data._id)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const menu2 = (data) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            history.push(`addeditsurvey?id=${data._id}&m=result`);
          }}
        >
          View Result
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            downloadfile(data);
          }}
        >
          Download
        </Menu.Item>
      </Menu>
    );
  };

  const handleTableChange = (pagination, filter, sort) => {
    getPostTable(pagination.current);
  };

  const downloadfile = (userData) => {
    var createXLSLFormatObj = [];

    /* XLS Head Columns */
    var xlsHeader = [
      "No",
      "Title",
      "SurveyorType",
      "User",
      "Questions",
      "Answer",
    ];

    /* XLS Rows Data */

    var xlsRows = [];

    if (userData) {
      userData.participants.length &&
        userData.participants.map((data, i) =>
          xlsRows.push({
            No: i,
            Title: userData.title,
            SurveyorType: userData.surveyorType,
            User:
              userData.participants[i].user &&
              userData.participants[i].user.name,
            Questions: userData.participants[i].question,
            Answer: userData.participants[i].answer,
          })
        );
    } else {
      data.map(
        (da, index) =>
          da.participants.length &&
          da.participants.map((data, i) =>
            xlsRows.push({
              No: i,
              Title: da.title,
              SurveyorType: da.surveyorType,
              User: da.participants[i].user && da.participants[i].user.name,
              Questions: da.participants[i].question,
              Answer: da.participants[i].answer,
            })
          )
      );
    }

    createXLSLFormatObj.push(xlsHeader);

    $.each(xlsRows, function (index, value) {
      var innerRowData = [];
      $.each(value, function (ind, val) {
        innerRowData.push(val);
      });
      createXLSLFormatObj.push(innerRowData);
    });

    /* File Name */
    var filename = "Report.xlsx";

    /* Sheet Name */
    var ws_name = "Report";

    if (typeof console !== "undefined") console.log(new Date());
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* Write workbook and Download */
    if (typeof console !== "undefined") console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== "undefined") console.log(new Date());
  };

  const convertJsonToExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "students");
    // Generate buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "surveyreport.xlsx");
  };

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar active={4} />
        <div className="right-sec">
          <div className="headingBar">
            <h1 className="fw700 fs36 lh36">Total Surveys</h1>
            <Button
              onClick={() => history.push("addeditsurvey?m=add")}
              type="primary"
            >
              Add New Survey
            </Button>
          </div>
          <div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: true,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={columns}
                dataSource={data}
              />
            </div>
          </div>

          <div>
            <div
              className="headingBar"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1
                style={{ marginTop: 30, marginBottom: 30 }}
                className="fw700 fs36 lh36"
              >
                Surveys Report
              </h1>
              <Button onClick={() => downloadfile(null)} type="primary">
                Download
              </Button>
            </div>
            <div className="table-card" style={{ marginTop: 20 }}>
              <Table
                pagination={{
                  total,
                  pageSize: 10,
                  showQuickJumper: true,
                  responsive: true,
                  showTotal: (total, range) => `${total} records`,
                }}
                onChange={handleTableChange}
                loading={loading}
                columns={column2}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Survey;
