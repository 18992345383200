import React from "react";
import { Upload } from "antd";
import "./styles.css";

export default function UploadFile({ label, classes, setFile, file }) {
  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fileSelected = (e) => {
    // extract selected file
    const file = e.file.originFileObj;

    // if file not available return
    if (!file) return;

    // set state with seleted file
    setFile(file);
    //setImageUrl(file.name)
  };
  return (
    <div className="cst-textinput cst-text-width">
      <label className="fw600 fs24 lh48">{label}</label>

      <div
        className="uploadBox"
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <Upload
          dummyRequest={dummyRequest}
          showUploadList={false}
          onChange={(e) => fileSelected(e)}
        >
          <p style={{ marginTop: 8 }}>{file ? "selected" : "File"}</p>
        </Upload>
      </div>
    </div>
  );
}
