import React from "react";
import { Spin } from "antd";

const Loader = ({ tip }) => {
  return (
    <div className="text-center pt-20">
      <Spin tip={tip} size="large" />
    </div>
  );
};
export default Loader;
