import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useQueryParams, openNotification } from "../../helpers";
import { useHistory } from "react-router-dom";
import NavBar from "../../components/navbar";
import RequestBar from "../../components/RequestBar";
import Sidebar from "../../components/sidebar/sideBar";
import { patch, get, deleteService } from "../../services/RestService";
import { Image, Spin } from "antd";

export default function ViewObserver() {
  const history = useHistory();
  const query = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [oData, setOData] = useState({});
  const id = query.get("id");

  const approveObserver = () => {
    setLoading(true);
    console.log(id);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const dataForm = null;
    patch(`/auth/user/approve/${id}`, dataForm, options)
      .then((res) => {
        console.log(res);
        setLoading(false);
        openNotification("Success");
        history.push("observer");
      })
      .catch((err) => {
        openNotification(err.message);
        setLoading(false);
        console.log(err.data);
      });
  };

  const deletePost = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    deleteService(`/auth/users/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        openNotification("Success");
        history.push("observer");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    var options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    get(`/auth/users/${id}`, options)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setOData(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      <NavBar />
      <div style={{ display: "flex" }}>
        <Sidebar />
        {loading ? (
          <Spin />
        ) : (
          <div className="right-sec">
            <div className="headingBar jasdklf">
              <div
                className="d-flex align-items-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 20, cursor: "pointer" }}
                  onClick={() => history.push("observer")}
                />
                <h1 className="fw700 fs36 lh36 ml-4" style={{ marginLeft: 40 }}>
                  Observer {!oData.approved && "Request"}
                </h1>
              </div>

              <div
                style={{ display: "flex" }}
                className="d-flex align-items-center "
              >
                {!oData.approved && (
                  <button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                      deletePost();
                    }}
                    className="btn d-btn fw500 bg-black fs24 lh36 bg-red mr-5"
                  >
                    Remove
                  </button>
                )}
                {!oData.approved && (
                  <button
                    onClick={() => {
                      approveObserver(true);
                    }}
                    className="btn d-btn fw500 bg-red fs24 lh36"
                  >
                    Approve
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex m-auto align-items-center justify-content-center">
              <div className="request-bar-parrent">
                <RequestBar
                  head="Profile Picture:"
                  value={
                    <Image
                      style={{ width: 50, height: 50, borderRadius: 999 }}
                      src={oData.image && oData.image}
                      size="large"
                    />
                  }
                />
                <RequestBar head="Observer’s Name:" value={oData.name} />
                <RequestBar
                  head="Observer’s Email Address:"
                  value={oData.email}
                />
                <RequestBar
                  head="Observer’s Phone Number:"
                  value={oData.phone}
                />
                <RequestBar
                  head="Employment:"
                  value={
                    oData.employment === '"Yes"' ? "Employed" : "Unemployed"
                  }
                />
                <RequestBar head="Gender:" value={oData.gender} />
                <RequestBar head="Academic Degree:" value={oData.degreeTitle} />
                <RequestBar
                  head="Election Center:"
                  value={oData.electionCenter}
                />
                {/* <RequestBar head='Location:' value={oData.degreeTitle} /> */}
                <RequestBar
                  head="Date of Birth:"
                  value={oData.dob && oData.dob.substring(0, 10)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
